@import "common/styles.module.sass"

.headerContainer
  padding-bottom: 16px
  display: flex
  align-items: center
  flex-shrink: 0
  gap: 12px
  border-bottom: 1px solid $BOTTOM_BORDER
  .chatHeader
    width: 100%
  .groupChatHeader
    display: flex
    flex-direction: column
    gap: 24px
  .groupChatTitle
    .backIcon
      display: none
    display: flex
    grid-gap: 15px
  .name
    color: $TEXT_PRIMARY

  .images
    display: flex
    gap: 16px
    .headerElement
      align-items: center
      display: flex
      flex-direction: column
      gap: 4px
    > svg
      display: none
    .myImg
      display: none
    .groupParticipantImage
      width: 44px
      height: 44px
  .chatHeader
    .name
      color: $TEXT_PRIMARY
      @include textTruncate(1)
  .imgContainer
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    cursor: pointer
    &.cursorNone
      cursor: default
.online
  display: flex
  gap: 4px
  color: $BLUE
  align-items: center
.displayName
  display: none
.borderIcon
  display: none

@media (max-width: $breakpoint-lg)
  .groupChatHeaderContainer
    border-bottom: 2px solid $LIGHT_BLUE
  .headerContainer
    padding: 30px 24px 4px 24px
    width: 100%
    .imgContainer
      margin-right: unset
    .images
      justify-content: space-around
      > svg
        display: block
        margin-top: 22px
      .myImg
        display: flex
    .mobileImg
      display: none
    .groupChatHeader
      width: 100%
      gap: 18px
    .groupChatTitle
      margin-bottom: unset
      .backIcon
        cursor: pointer
        display: flex
        align-items: center
      .name
        width: 100%
        text-align: center
  .online
    margin-top: 10px
    text-align: center
    justify-content: center
  .displayName
    display: block
    text-align: center
    color: $DARK_BLUE
    @include textTruncate(2)
    word-break: normal
    max-width: 62px
    height: 100%
