@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

@keyframes messages
  0%
    transform: translate(0px, -50px)
  25%
    transform: translate(0px, -25px)
  100%
    transform: translate(0px, 0px)

@keyframes spin
  @include spinner

.headerNavBar
  padding: 0 16px
  gap: 24px
  display: grid
  .titleStyles
    @include DesktopUILSemibold()
.container
  display: flex
  flex-direction: column
  overflow-y: auto
  height: 100%
  gap: 16px
  @include scrollbar
  .loadingContainer
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    & > svg
      animation: spin 2s linear infinite
  .searchLoadingContainer
    display: flex
    align-items: center
    justify-content: center
    & > svg
      animation: spin 2s linear infinite
      height: 26px 
  .listContainer
    display: flex
    padding-right: 8px
    flex-direction: column
    height: 100%
    overflow-x: hidden
    overflow-y: auto
    @include scrollbar
    > div
      animation: messages 0.5s linear forwards
  .inviteButton
    position: absolute
    right: 18px
    bottom: 40px
    .inviteIcon
      @include invitIconeStyles()

@media (max-width: $breakpoint-lg)
  .container
    min-height: 80%
    width: 100%
    .headerContainer
      padding: 14px 24px

@media (max-width: $breakpoint-sm)
  .container
    width: 100%
    max-width: 100%

@media (max-width: $breakpoint-xs)
  .container
    border-radius: unset
    @include heightScrollableComponentParent()
    .listContainer
      margin: unset
      padding: 0 24px
    .searchInput
      width: initial
      margin: 16px 24px
  .headerNavBar
    padding: 0
