@import "common/styles.module.sass"

.preloader
  animation: rotate-center 1s linear 0s infinite
  image-rendering: auto
  shape-rendering: auto
@keyframes rotate-center
  @include spinner
.user
  margin-left: 2px
  margin-top: -2px
.close
  stroke: #CDD5E5
  transition: 0.2s
