@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.popup
  @include popupContent()
  .option
    @include popupContentOption()
    @include DesktopUIMMedium()
    &:hover
      background-color: $GREY_BACKGROUND
  .vacancy
    white-space: nowrap

@media (max-width: $breakpoint-sm)
  .popup
    overflow: auto
    height: fit-content
