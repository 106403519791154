@import "common/styles.module.sass"

.publicHeader
  height: 86px
.header
  height: 86px
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  padding: 15px 70px
  position: sticky
  top: 0
  z-index: 10
  background: $WHITE
  box-shadow: 0px 4px 12px 0px #0000000F
  .content
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    height: 100%
    .publicLogo
      max-width: 90px
      max-height: 40px
    .logo
      width: 90px
      height: 40px
    .settings
      display: flex
      align-items: center
      gap: 5px
      .profileImage
        cursor: pointer
    .miniNavbar
      display: none
      align-items: end
      height: 100%
      padding: 0 0 10px 0

@media (max-width: $breakpoint-lg)
  .header
    height: unset
    .content
      justify-content: center
      height: 44px
      .miniNavbar
        display: block
        width: 100%
        padding-top: 10px
      .logo
        display: none
      .settings
        display: none
  .publicHeader
    height: 86px
@media (max-width: $breakpoint-xs)
  .header
    .content
      height: unset
      .miniNavbar
        display: none
