@import "common/styles.module.sass"

.rightSideMsgsContainer
  position: fixed
  right: 50px
  bottom: 50px
  width: 350px
  z-index: 150
  background-color: #E6ECF4
  border-radius: 15px
  padding: 5px
  max-height: 80vh
  overflow-y: auto
  @include scrollbar
  .closeAllNotifications
    display: flex
    justify-content: space-between
    padding: 10px
    .close
      cursor: pointer
  .msg
    width: 100%
    padding: 20px
    background: #FFFFFF
    border-radius: 10px
    overflow: hidden
    box-shadow: 0px 0px 6px 1px #00000029
    position: relative
    margin-bottom: 20px
    cursor: pointer
    &:last-child
      margin-bottom: 0
    > div
      display: flex
      align-items: center
    .photoContainer
      width: 50px
      height: 50px
      border-radius: 100%
      border: 1px solid #e1e1e1
      flex-shrink: 0
      margin-right: 20px
      background: #e1e1e1
      display: flex
      justify-content: center
      align-items: center
      overflow: hidden
      svg
        transform: scale(0.5)
    .contentContainer
      display: flex
      flex-direction: column
      .displayName
        font-size: 17px
        color: $DARK_BLUE
        margin-bottom: 5px
        @include textTruncate(1)
        word-break: break-word
      .text
        font-size: 15px
        color: #818181
        @include textTruncate(2)
        word-break: break-word
      .mediaText
        color: $BLUE
    .close
      position: absolute
      top: 0
      right: 0
      width: 30px
      height: 30px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      svg
        stroke: #646464
        transform: scale(0.6)
.incomingCallContainer
  position: fixed
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 400px
  height: 400px
  background: #9a9a9a
  top: 50%
  left: 50%
  margin-left: -200px
  margin-top: -200px
  border-radius: 20px
  animation: incomingCallColors 2s infinite
  z-index: 100
  .photoContainer
    width: 200px
    height: 200px
    border-radius: 100%
    background: #d2d2d2
    flex-shrink: 0
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    img
      width: 100%
  .displayName
    font-size: 20px
    margin-top: 20px
    margin-bottom: 10px
  .event
    margin-bottom: 20px
  .buttonsContainer
    display: flex
    .button
      width: 50px
      height: 50px
      background: #FFFFFF
      border-radius: 100%
      margin-right: 30px
      cursor: pointer
      display: flex
      justify-content: center
      align-items: center
      &:last-child
        margin-right: 0

@keyframes incomingCallColors
  0%
    background-color: #9a9a9a
  50%
    background-color: #838383
  100%
    background-color: #9a9a9a

.loading
  width: 200px
  height: 100px!important
  display: flex
  justify-content: center
  align-items: center
  padding: 0!important
  &:hover
    background: #FFFFFF!important
    cursor: default!important
@keyframes load
  from
    left: -200px
  to
    left: 100%
