@import "common/styles.module.sass"
hr 
  border-top: 1px solid $SHADES_PRIMARY_10

.emptyGroupContainer
  display: flex
  flex-direction: column
  align-items: center
  gap: 72px
.groupContainer
  @include groupLayoutContent()

.headerContainer
  width: 100%
  display: grid
  gap: 36px
.buttons
  display: flex
  justify-content: space-between
  gap: 36px
  .actionButton
    height: 40px
    display: flex
    gap: 16px
    align-items: center
    > svg
      cursor: pointer
      width: 40px
      height: 40px
      > path
        stroke: $TEXT_PRIMARY
    .popupButtonStyles
      background: none
      > div > div > svg
        width: 40px
        height: 40px
.headerTitle
  text-align: center
  color: $TEXT_PRIMARY
  word-break: break-all

.participantsContainer
  @include groupParticipantsContainer()

.shareLinkContainer
  background: $BACKGROUND_LIGHT_01
.emptyContainerButtons
  display: flex
  gap: 32px
  justify-content: center
  .copyButtonContainer
    min-width: 160px

.membersHeader 
  display: flex
  justify-content: space-between
  gap: 10px
  width: 100%
  > button
    flex: 1

.membersSection 
  @include groupMembersContainer()
  .membersTitle
    display: flex
    gap: 5px
    color: $TEXT_PRIMARY
    p:nth-child(2)
      color: $TEXT_THIRTARY
  .membersList 
    display: grid
    gap: 16px

.searchContainer
  width: 100%
  display: flex
  gap: 16px
.emptySearchStateContainer
  display: flex
  height: 30vh

.modalDescription
  color: $TEXT_PRIMARY
  word-break: break-word

.groupHeaderAndTitleStyles
  display: grid
  gap: 8px !important
.modalContent
  display: grid
  gap: 16px
  .subTitle
    color: $TEXT_THIRTARY
  .groupLinkInfoContainer
    display: grid
    align-items: center
    gap: 16px
    color: $TEXT_SECONDARY
    .linkContainer
      display: flex
      justify-content: space-between
      align-items: center
      padding: 12px 0
      color: $BACKGROUND_PRIMARY
      gap: 50px
      .copiedTextContainer
        display: flex
        gap: 8px
        .linkText
          @include textTruncate(1)
        .copiedText
            cursor: pointer
  .resetLinkButton
    cursor: pointer
    display: flex
  .groupAccess
    display: grid
    align-items: center
    gap: 16px
    color: $TEXT_SECONDARY
    .groupAccessAction
      justify-content: space-between
      display: flex
      gap: 8px
      padding: 8px 0
      .groupAccessActionData
        display: flex
        gap: 8px
        align-items: center
        .approvalText
          color: $TEXT_SECONDARY
    .toggleText
      color: $TEXT_THIRTARY

.joinModalContainer
  display: grid
  gap: 8px
  .spinner
    height: 170px
    display: flex
    align-items: center
    justify-content: center
  .joinModalDescription
    display: flex
    gap: 8px
    width: fit-content
    place-self: center
    align-items: center
    padding: 6px 12px
    background-color: $BACKGROUND_LIGHT_01
    border-radius: 36px
    color: $TEXT_SECONDARY
    .membersCount
      color: $BLUE
  .blockedText
    color: $RED
    text-align: center
    padding: 20px
.footerStyles
  > button
    width: 100%

.groupInviteOptionsModalContent
  display: grid 
  gap: 16px
  .groupInfo
    display: grid 
    gap: 24px
    .accessInfo
      display: grid 
      gap: 4px
      .infoText
        color: $TEXT_THIRTARY
      .infoButton
        color: $BLUE
        cursor: pointer
  .inviteOptions
    display: grid
    gap: 16px
    justify-items: left
    .optionButton
      color: $BLUE
      padding: 8px 0
      min-height: 40px

.usersActionswrapper
  .containerStyles
    padding-bottom: 32px
    max-height: 524px
  .modalHeader
    display: flex
    align-items: center
    gap: 8px
  .modalContent
    min-height: 340px
  .userList
    display: flex
    flex-direction: column
    gap: 8px
    .userStyles
      padding: 8px 0
      > div > button
        min-height: 36px
        height: 36px

.emptySearchStatesActions
  > svg
    max-height: 220px

