@import "common/styles.module.sass"

.popupTrigger
  padding: 0
  width: 100%
  cursor: pointer
.menuButtons
  background: WHITE
  min-width: 170px
  gap: 8px
  padding: 8px 0
  border-radius: 8px
  box-shadow: 0px 5px 8px 0px $POPUP_SHADOW
  .menuItem
    padding: 4px 8px
    gap: 8px
    height: 32px
    display: flex
    align-items: center
    cursor: pointer
    color: $TEXT_TEXT_DARK
    letter-spacing: 0.2px
    text-align: left
    &:hover
      background: $SHADES_PRIMARY_10
    .deleteTitle
      color: $GLOBAL_ACCENT_3
  .otherContact
    cursor: default
    color: $GRAY_DISABLED
    &:hover
      background: $WHITE
  .reportJobText
    color: red
.popupContent
  @include fontSize14($DARK_GREY, 400)
  padding: 6px 16px
  min-width: 108px
  @include popupShadow()
  white-space: nowrap
.loadingIcon
  svg
    position: inherit
    width: 26px
    height: 26px
