@font-face {
  font-family: 'SF Pro Display';
  src: url('/public/SFProDisplay/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/public/SFProDisplay/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/public/SFProDisplay/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/public/SFProDisplay/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Display';
}

a {
  text-decoration: none;
  display: flex;
}

body {
  margin: 0;
  background-color: #F8FAFF;
  font-family: 'SF Pro Display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
  visibility: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
