@import "common/styles.module.sass"

.toast
  width: 350px
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 20px 16px
  border-radius: 16px
  gap: 12px
  box-shadow: 3px 3px 4px 0px #9E9E9E1A
  background-color: #FFFFFFF2
  word-break: break-all

.content
  width: 100%
  display: grid
  gap: 4px
  .message
    text-align: start
    color: $TEXT_SECONDARY

.close
  cursor: pointer
  height: 24px
