@import "common/styles.module.sass"

.navBarOnlyMobile
  display: none
.container
  display: flex
  justify-content: space-between
  align-items: center
  gap: 56px
  > .navLink
    display: flex
    justify-content: flex-end
    align-items: center
    flex-flow: column
    height: 100%
    grid-gap: 6px
  .border
    height: 3px
  .iconContainer
    display: flex
    align-items: center
    position: relative
  .messageUnreadIcon
    position: absolute
    background: $BLUE
    color: $BACKGROUND_LIGHT_01
    display: flex
    gap: 10px
    padding: 0 4px 0 5px 
    top: -6px
    left: 18px
    min-width: 21px
    height: 21px
    border-radius: 36px
    align-items: center
    justify-content: center
    &.activeUnreadIcon
      background: $BACKGROUND_LIGHT_01
      color: $BLUE
  &.unauthorized
    justify-content: flex-end
    > a
      margin-right: 15px
      &:last-child
        margin-right: 0
.activeLinkAdmin
  path
    fill: #FFB600
  rect
    fill: #FFB600

@media (max-width: $breakpoint-lg)
  .gradientBg
    height: unset
    width: 100%
    padding-bottom: $NAVBAR_MOBILE_BOTTOM_DISTANCE
    background: linear-gradient(360deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)
    position: fixed
    bottom: 0
    justify-content: center
    display: flex
  .container
    background-color: $WHITE
    display: flex
    justify-content: center
    border-radius: 80px
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15)
    padding: 16px 26px 6px
    width: max-content
    gap: 12px
    .iconContainer
      .messageUnreadIcon
        top: -5px
        left: 35px
        width: 17px
        height: 17px
        &.activeUnreadIcon
          background: $BACKGROUND_LIGHT_01
          color: $BLUE
    > .navLink
      grid-gap: 2px
    .hideOnMobile
      display: none
    .iconContainer
      width: unset
      padding: 0 20px
      svg
        width: 22px
        height: 22px
  .navBarOnlyMobile
    display: block
    margin: 0 auto
  .hideNavbar
    display: none
