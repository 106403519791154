@import "common/styles.module.sass"

@mixin Body1Medium()
  font-family: "SF Pro Display"
  font-size: 16px
  font-weight: 500
  line-height: 24px
  text-align: left

@mixin Body1Regular()
  font-family: "SF Pro Display"
  font-size: 16px
  font-weight: 400
  line-height: 24px

@mixin Body2Regular()
  font-family: "SF Pro Display"
  font-size: 14px
  font-weight: 400
  line-height: 22px

@mixin DesktopUIXXXSMedium()
  font-family: "SF Pro Display"
  font-size: 5px
  font-weight: 500
  line-height: 8px
  letter-spacing: 0.2px
  text-align: center

@mixin DesktopUIXXSMedium()
  font-family: "SF Pro Display"
  font-size: 10px
  font-weight: 500
  line-height: 18px
  letter-spacing: 0.2px
  text-align: center

@mixin DesktopUISMedium()
  font-family: "SF Pro Display"
  font-size: 14px
  font-weight: 500
  line-height: 20px
  letter-spacing: 0.2px

@mixin DesktopUIMRegular()
  font-family: "SF Pro Display"
  font-size: 16px
  font-weight: 400
  line-height: 24px
  text-align: left

@mixin DesktopUIMMedium()
  font-family: "SF Pro Display"
  font-size: 16px
  font-weight: 500
  line-height: 24px

@mixin DesktopUILMedium()
  font-family: "SF Pro Display"
  font-size: 20px
  font-weight: 500
  line-height: 26px
  text-align: left

@mixin DesktopUIXLMedium()
  font-family: "SF Pro Display"
  font-size: 24px
  font-weight: 500
  line-height: 30px
  text-align: left  

@mixin DesktopUIXXXLMedium()
  font-family: "SF Pro Display"
  font-size: 48px
  font-weight: 600
  line-height: 56px  

@mixin DesktopUIXXLMedium()
  font-family: "SF Pro Display"
  font-size: 32px
  font-weight: 600
  line-height: 40px

@mixin DesktopUIXLBold()
  font-family: "SF Pro Display"
  font-size: 24px
  font-weight: 700
  line-height: 32px
  text-align: left

@mixin DesktopUIXLLight()
  font-family: "SF Pro Display"
  font-size: 24px
  font-weight: 300
  line-height: 32px
  text-align: left

@mixin DesktopUIXLRegular()
  font-family: "SF Pro Display"
  font-size: 24px
  font-weight: 400
  line-height: 32px

@mixin DesktopUILSemibold()
  font-family: "SF Pro Display"
  font-size: 20px
  font-weight: 600
  line-height: 28px
  text-align: left

@mixin DesktopUILRegular()
  font-family: "SF Pro Display"
  font-size: 20px
  font-weight: 400
  line-height: 28px
  text-align: left

@mixin DesktopUISBold()
  font-family: "SF Pro Display"
  font-size: 14px
  font-weight: 600
  line-height: 26px
  letter-spacing: 0.2px
  text-align: left

@mixin DesktopUIXSBold()
  font-family: "SF Pro Display"
  font-size: 12px
  font-weight: 600
  line-height: 26px
  letter-spacing: 0.2px
  text-align: center

@mixin DesktopUISRegular()
  font-family: "SF Pro Display"
  font-size: 14px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0.2px
  text-align: left
  color: $TEXT_TEXT_DARK

@mixin DesktopUIMSemibold()
  font-family: "SF Pro Display"
  font-size: 16px
  font-weight: 600
  line-height: 20px
  text-align: center

@mixin Body2Medium()
  font-family: "SF Pro Display"
  font-size: 14px
  font-weight: 500
  line-height: 22px

@mixin Body3Medium()
  font-family: "SF Pro Display"
  font-size: 12px
  font-weight: 500
  line-height: 16px

@mixin Body3Semibold()
  font-family: "SF Pro Display"
  font-size: 12px
  font-weight: 600
  line-height: 16px

@mixin HeadingsH6Regular()
  font-family: "SF Pro Display"
  font-size: 20px
  font-weight: 400
  line-height: 28px

@mixin HeadingsH6Medium()
  font-family: "SF Pro Display"
  font-size: 20px
  font-weight: 500
  line-height: 28px
  letter-spacing: 0

.Desktop_UI_XXXL_Medium
  @include DesktopUIXXXLMedium()

.Desktop_UI_XXL_Medium
  @include DesktopUIXXLMedium()

.Desktop_UI_XL_Bold
  @include DesktopUIXLBold()

.Desktop_UI_XL_Light
  @include DesktopUIXLLight()

.Desktop_UI_XL_Regular
  @include DesktopUIXLRegular()

.Desktop_UI_L_Semibold
  @include DesktopUILSemibold()

.Desktop_UI_L_Regular
  @include DesktopUILRegular()

.Desktop_UI_XXXS_Medium
  @include DesktopUIXXXSMedium()

.Desktop_UI_XXS_Medium
  @include DesktopUIXXSMedium()

.Desktop_UI_S_Bold
  @include DesktopUISBold()

.Desktop_UI_XS_Bold
  @include DesktopUIXSBold()

.Desktop_UI_S_Regular
  @include DesktopUISRegular()

.Desktop_UI_S_Medium
  @include DesktopUISMedium()

.Desktop_UI_M_Regular
  @include DesktopUIMRegular()

.Desktop_UI_M_Medium
  @include DesktopUIMMedium()

.Desktop_UI_L_Medium
  @include DesktopUILMedium()

.Desktop_UI_XL_Medium
  @include DesktopUIXLMedium()

.Desktop_UI_M_Semibold
  @include DesktopUIMSemibold()

.Body_1_Medium
  @include Body1Medium()

.Body_1_Regular
  @include Body1Regular()

.Body_2_Regular
  @include Body2Regular()  

.Body_2_Medium
  @include Body2Medium()

.Body_3_Medium
  @include Body3Medium()

.Body_3_Semibold
  @include Body3Semibold()

.Headings_H6_Regular
  @include HeadingsH6Regular()

.Headings_H6_Medium
  @include HeadingsH6Medium()  
