@import "common/styles.module.sass"

.container
  display: flex
  width: 100%
  justify-content: flex-end
  button
    border: none
  .joinUs
    @include fontSize14($WHITE, 500)
  .signIn
    @include fontSize14($BLUE, 500)
.mobileUnAuthNavBar
  display: none

@media (max-width: $breakpoint-xs) 
  .mobileUnAuthNavBar
    padding: 16px
    display: flex
    display: flex
    min-width: 344px
    background-color: $WHITE
    border-radius: 8px
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    width: 264px
    position: fixed
    bottom: 30px
    .linkForUploadApp
      gap: 14px
      justify-content: center
      display: flex
      .logo
        width: 76px
      p
        @include fontSize16($DARK_BLUE, 500)
      a
        display: unset
        @include fontSize16($BLUE, 700)
        border-bottom: 2px solid $BLUE
