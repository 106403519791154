@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

@mixin inputTextStyles ()
  @include Body1Regular()
  padding: 4px

.tagsList
  margin-bottom: 16px
  display: flex
  flex-wrap: wrap
  gap: 8px
  div
    display: flex
    gap: 4px
    align-items: center
    margin-right: 4px
    svg
      cursor: pointer
  p
    @include textTruncate(1)
    color: $TEXT_SECONDARY
    line-height: 1.3
    span
      color: $WALKAWAY_GREY
  button
    border: none
    background: none
    display: flex

.inputTextContainer
  position: relative
  .formInputIcon
    position: absolute
    top: 17px
    right: 13px
    width: max-content
    cursor: pointer
  .textareaContainer
      border-radius: 8px
      border: 1px solid $BORDER_REGULAR
      padding-top: 16px
      &:focus
        border: 1px solid $BLUE
      &::placeholder
        padding-top: 5px
        @include Body1Regular()
        color: $TEXT_THIRTARY
      .textarea
        padding: 0 16px
        min-height: 56px
        max-height: 170px
        width: 100%
        border: none
        resize: none
        outline: none
        color: $TEXT_SECONDARY
        @include Body1Regular()
        @include scrollbar
        &::placeholder
          @include Body1Regular()
          color: $TEXT_THIRTARY
  .invalidTitle
    color: $RED
  > label
    color: $TEXT_SECONDARY
    top: -9px
    left: 15px
    position: absolute
    background: $WHITE
    padding: 0 3px
    height: 15px
  .placeholderTitle
    @include Body1Regular()
    color: $TEXT_THIRTARY
    opacity: 0.6
    position: absolute
    left: 16px
    top: 16px
    z-index: 9
    user-select: none
    cursor: text
  input
    @include Body1Regular()
    height: 56px
    min-width: 0
    width: 100%
    resize: none
    outline: none
    padding: 10px 16px
    border: 1px solid $BORDER_REGULAR
    border-radius: 8px
    &.withIcon
      padding-right: 40px
    &:focus
      border: 1px solid $BLUE
    &::placeholder
      @include Body1Regular()
      color: $TEXT_THIRTARY
  .errorBorder
    border: 1px solid $RED
  .inputRequired
    position: relative
    &::after
      content: '*'
      position: absolute
      top: 3px
      left: 46px
      color: #f00
.footerInputText
  padding: 4px 0 0 0
  display: flex
  justify-content: space-between
  color: $TEXT_THIRTARY
.counter
  display: flex
  justify-content: flex-end
.inputHelperText
  color: $TEXT_THIRTARY
.inputError
  color: $RED
.errorText
  color: $RED

.tagsContainer
  position: relative

.tagsTitle
  color: $TEXT_SECONDARY
  position: absolute
  top: -10px
  left: 15px
  background: white
  padding: 0 3px
  &.errored
    color: $RED

.locationsTitle 
  color: $TEXT_SECONDARY
  position: absolute
  top: -10px
  left: 15px
  background: white
  padding: 0 3px
  &.errored
    color: $RED

.locationItem
  display: inline-flex
  align-items: center
  svg
    margin-right: 8px

.tagsInputContainer
  cursor: text
  display: flex
  flex-wrap: wrap
  align-items: center
  border: 1px solid $BORDER_REGULAR
  padding: 8px
  border-radius: 8px
  min-height: 56px
  width: 100%
  gap: 16px
  &.errored
    border: 1px solid $RED

  .tag
    display: flex
    align-items: center
    background-color: $BACKGROUND_LIGHT_01
    border-radius: 4px
    padding: 4px 8px
    .deleteTag
      cursor: pointer
      align-items: center
      gap: 4px
      font-weight: bold
      display: flex
  .inputValue
    @include inputTextStyles()
    border: none
    outline: none
    min-width: 20px
    background-color: transparent
    width: auto
    &::placeholder
      color: $TEXT_THIRTARY
  .hasValue
    background-color: $BACKGROUND_LIGHT_01

.locationContainer
  display: flex
  flex-direction: column
  position: relative
  > div > div 
    flex-wrap: wrap

.locationInputContainer
  display: flex
  align-items: center
  border: 1px solid $BORDER_REGULAR
  border-radius: 8px
  min-height: 56px
  padding: 8px 16px
  &.errored
    border: 1px solid $RED
  &:focus
    border: 1px solid $BLUE
  .inputContainer
    display: flex
    flex-wrap: wrap
    gap: 16px
  .expandableInputWrapperStyles
    border: none
    margin-top: 0 !important
    display: grid
    align-items: center
    position: unset
    &:focus
      border: none
    input
      height: 20px
  .popupMenu
    top: unset !important

.locationExpandableInputStyles
  height: 25px
  input
    @include Body1Regular()
    border: none !important
    outline: none
    padding: 4px
    background-color: transparent
    width: auto
    &::placeholder
      color: $TEXT_THIRTARY
    &:focus
      border: none
    &.hasValue
      height: auto
      border: none
      background-color: $BACKGROUND_LIGHT_01
  > span
    display: none

.tag
  display: flex
  align-items: center
  background-color: $BACKGROUND_LIGHT_01
  border-radius: 4px
  padding: 4px 8px
  .tagTitle
    margin-right: 4px
    color: $TEXT_SECONDARY
  .deleteTag
    cursor: pointer
    align-items: center
    margin-left: 8px
    font-weight: bold
    display: flex

.option
  @include DesktopUIMMedium()
  display: flex
  align-items: center
  padding: 8px
  cursor: pointer
  &:hover
    background-color: $BACKGROUND_LIGHT_01

.hiddenSpan
  @include inputTextStyles()
  @include textTruncate(1)

.tagsLimit 
  font-size: 12px
  color: #6b7280
  margin-top: 4px

.helperText
  font-size: 12px
  color: #6b7280
  margin-top: 4px

.errorText
  font-size: 12px
  color: red
  margin-top: 4px

.popupMenu
  z-index: 9999
  position: absolute
  width: 99%
  background-color: $WHITE
  overflow-y: auto
  box-shadow: 0px 5px 8px 0px $POPUP_SHADOW
  .popup
    max-height: 30vh

.urlFieldContainer
  display: flex
  align-items: center
  gap: 16px
  > div
    width: 100%
  input
    color: $BACKGROUND_PRIMARY
