@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.locationTypes
  position: relative
  display: flex
  flex-direction: column
  padding-left: 4px
  gap: 12px
  > label
    color: $TEXT_SECONDARY
    background: white
  .locationType
    width: 100%
    display: flex
    justify-content: flex-start
    border-radius: 8px
    @include Body2Regular()
    color: $TEXT_SECONDARY
    gap: 16px
    .button
      @include Body2Regular()
      height: 36px
      gap: 8px
      padding: 6px 12px
      border-radius: 24px
      cursor: pointer
      user-select: none
      display: inline-flex
      justify-content: center
      min-width: 80px
      background-color: $BACKGROUND_LIGHT_01
      position: relative
      &.selected
        background-color: rgba(136, 198, 255, 0.32)
      .checkmark
        display: flex
    &.isInvalid
      .button
        border: 1px solid $RED
        background-color: rgba($GLOBAL_ACCENT_3, 0.03)
  .invalidTitle
    color: $RED
  .inputError
    color: $RED
    margin-top: 2px
    margin-left: 16px
.errorMessageSalary
  margin-top: -16px
  margin-bottom: 16px
  color: $RED
.vacancyInputGroupPayment
  display: flex
  gap: 6px
  padding: 4px 0 20px 0
  & > :first-child
    width: 240px
  & > :last-child
    width: 134px

.vacancyInputGroup
  grid-gap: 16px
  > div
    padding-bottom: 2px
    margin-bottom: 0
    > div
        max-width: unset !important
textarea
  color: $DARK_BLUE
  width: 100%
  border: none
  resize: none
  outline: none
  min-height: 80px
  &::placeholder
    @include Body1Regular()
    color: $TEXT_THIRTARY
.exploreSuccessModal
  display: flex
  flex-direction: column
  gap: 16px
  .title
    color: $DARK_BLUE
  .message
    color: $WALKAWAY_GREY
    margin-bottom: 32px

.form
  max-height: calc(100vh - 180px)
  display: flex
  flex-direction: column
  background: white
  gap: 32px
  .header
    display: flex
    .title
      color: $TEXT_SECONDARY 
    .close
      margin-left: auto
      > svg
        stroke: $TEXT_SECONDARY
      &:hover
        cursor: pointer
        > svg
          stroke: $BLUE
  .content
    @include scrollbar($scrollbarM, $TEXT_THIRTARY)
    overflow-y: auto
    height: 100%
    padding-right: 20px
    display: grid
    .fieldContainer
      transition: all 0.7s ease
      max-height: 300px
      padding: 4px 0 20px 0
    .fieldHidden
      max-height: 0
      padding: 0
      clip-path: inset(0 0 0 0)
  .footer
    padding: 12px 0
@media (max-width: $breakpoint-xs)
  .form
    height: 100%
    width: 100%
    position: fixed
    left: 0
    top: 0
    display: flex
    flex-direction: column
    padding: 20px 0 20px 16px
