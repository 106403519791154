@import "common/styles.module.sass"

.editPhotoContainer
  padding: 12px 50px
  > div
    > img
      opacity: unset !important
  > canvas
    max-width: 100%
    max-height: auto
.buttonContainer
  margin-top: 12px
  display: flex
  gap: 12px
  justify-content: center
  border-radius: 8px
  > button
    border: unset
  .secondary
    background-color: $LIGHTER_GRAY
    @include fontSize16($DARK_BLUE, 500)
    &:hover
      background-color: $LINE_GREY
  .primary
    background-color: $BLUE
    @include fontSize16($WHITE, 500)
    &:hover
      background: $TRUST_BLUE
.photoContainer
  border-radius: 12px
  width: 112px
  height: 112px
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  position: relative
  img
    width: 100%
    border-radius: 12px
  svg
    margin-right: -3px
.photoContainerDisabled
  cursor: default
.inputFile
  display: none

.avatarContainer
  display: grid
  justify-items: center

@media (max-width: $breakpoint-xs)
  .buttonContainer
    flex-direction: column
