@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.button
  font-size: 16px
  height: 40px
  display: inline-flex
  align-items: center
  justify-content: center
  border: none
  outline: none
  border-radius: 8px
  color: $BLUE
  cursor: pointer
  transition: 0.2s
  background: none
  padding: 0 20px
  &:disabled
    opacity: 0.65
    cursor: auto
  &:hover
    border-color: $BLUE
.buttonWithIcon
  display: flex
  gap: 10px
  align-items: center
.iconRight
  flex-direction: row-reverse

@mixin ButtonCommon()
  @include Body1Medium()
  padding: 12px 16px
  border: none
  border-radius: 8px
  min-height: 48px

.secondaryButton
  @include ButtonCommon()
  background: $BACKGROUND_LIGHT_01
  color: $TEXT_SECONDARY
  &:hover
    background: $BACKGROUND_LIGHT_01_HOVER
  &:active
    background: $BACKGROUND_LIGHT_01_ACTIVE

.primaryButton
  @include ButtonCommon()
  background: $BACKGROUND_PRIMARY
  color: $BASE_WHITE
  &:hover
    background: $BACKGROUND_PRIMARY_HOVER
  &:active
    background: $BACKGROUND_PRIMARY_ACTIVE

.purpleButton
  @include ButtonCommon()
  background: $GLOBAL_ACCENT_2
  color: $BASE_WHITE
  &:hover
    background: $BACKGROUND_PURPLE_HOVER
  &:active
    background: $BACKGROUND_PURPLE_ACTIVE

.greenButton
  @include ButtonCommon()
  background: $GLOBAL_ACCENT_1
  color: $BASE_WHITE
  &:hover
    background: $BACKGROUND_GREEN_HOVER
  &:active
    background: $BACKGROUND_GREEN_ACTIVE

.transparentButton    
  @include ButtonCommon()
  background: $SHADES_PRIMARY_10
  color: $BLUE
  &:hover
    background: $SHADES_PRIMARY_20
  &:active
    background: $SHADES_PRIMARY_30
  &:disabled
    background-color: $Disabled_Dark
    color: $Disabled_White

.ghostButton
  @include ButtonCommon()
  background: none
  color: $BLUE
  &:hover
    background: $SHADES_WHITE_10
  &:active
    background: $SHADES_WHITE_20
  &.borderStyles
    border: 1px solid $SHADES_PRIMARY_10
