@import "common/styles.module.sass"
.wrapper
  width: 100vw
  height: 100vh
  background: $BACKGROUND_LIGHT_01
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  position: absolute
  left: 0
  top: 0
  .logo
    width: 96px
  .ticker
    height: 20px
    rotate: 180deg
