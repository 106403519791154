@import "common/styles.module.sass"
.subtitle
  display: table
  text-transform: capitalize
.emptyState
  display: grid
  gap: 20px
  height: 40px
  border-radius: 8px
  text-align: center
  align-items: center
  color: $TEXT_THIRTARY
  background: $BACKGROUND_LIGHT_01

.contactsContent
  @include contactsContent()
  .contactsList
    @include contactsList()

.emptyContactsContent, .emptyContactsList
  height: 100%

.spinner
  height: 90px
  display: flex
  align-items: center
  justify-content: center

.createGroupForm
  display: grid
  gap: 24px

.modalActions
  display: flex
  gap: 16px
  justify-content: right

.emptyContainer
  text-align: center
  max-width: 816px
  width: 100%
  height: 50vh
  display: flex
  flex-direction: column
  align-items: center
  gap: 32px
  @include scrollbar
  .selectContactText
    color: $WALKAWAY_GREY
    margin-top: 10px
  .emptyContainerInfo
    gap: 32px
    display: flex
    flex-direction: column
    justify-content: center
    max-width: 650px
    width: 100%
    .emptyContainerText
      display: grid
      gap: 8px
    .title
      max-width: 650px
      color: $TEXT_SECONDARY
      text-align: center
    .description
      max-width: 650px
      color: $TEXT_THIRTARY
      text-align: center

.dropdownMenu
  margin-left: -45px !important

.welcomeContactsContainer
  height: 100%
  .emptySearchContainer
    display: flex
    align-items: center
    margin-bottom: 40px
    max-width: 808px
    gap: 40px
  .emptyNetworkSpinner
    height: calc(100% - #{$FOOTER_HEIGHT})
    justify-self: center

.headerNavBar
  @include networkHeaderNavBar()
  .loading
    margin-bottom: 14px
    & > svg
      animation: spin 2s linear infinite

@media (max-width: $breakpoint-lg)
  .emptyContainer
    overflow: initial

@media (max-width: $breakpoint-md)
  .emptyContainer
    margin: auto

@media (max-width: $breakpoint-xs)
  .emptyContainer
    .emptyImage
      width: initial
    .title
      margin-top: -45px
    .description
      max-width: 327px
    .headerNavBar
      padding: unset
