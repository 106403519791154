@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.footer
  width: 100%
  .content
    @include DesktopUISRegular()
    color: $TEXT_THIRTARY
    display: flex
    align-items: center
    flex-wrap: wrap
    justify-content: center
    gap: 24px
    height: 70px
    border-top: 1px solid $SHADES_PRIMARY_10
    .linkText
      color: $TEXT_THIRTARY
      text-decoration: underline
      cursor: pointer
      p
        color: $TEXT_THIRTARY

@media (max-width: $breakpoint-lg)
  .footer
    &.hideOnMobile
      display: none

@media (max-width: $breakpoint-xxs)
  .footer
    .content
      padding: 8px
      gap: 8px
      height: 68px
