@import "common/styles.module.sass"

.container
  display: flex
  gap: 32px
  align-items: center
  justify-content: space-between
  width: 100%
  .headerTitle
    text-transform: capitalize
    color: $TEXT_PRIMARY
    min-width: max-content
    display: flex
    align-items: center
    gap: 8px
.miniNavbar
  justify-content: space-between
  align-items: end
  height: 100%
  .sideBar
    display: none

@media (max-width: $breakpoint-lg)
  .miniNavbar
    margin-bottom: unset
    align-items: center
    svg
      height: 36px
      margin-top: 4px
  .sideBar
    display: none

@media (max-width: $breakpoint-xs)
  .container
    justify-content: space-between
    width: 100%
    .headerTitle
      @include fontSize20($WHITE, 500)
      margin-right: 20px
  .sideBar
    display: block
  .mobile
    display: flex
    align-items: center
    gap: 20px
    padding: 20px
    background-color: $BLUE
    div
      margin: 0
      padding: 0
