@import "common/styles.module.sass"

.iconContainer
  height: 100%
  display: flex
  align-items: center
  margin: 0 auto
.preloader
  animation: rotate-center 0.7s linear 0s infinite
  image-rendering: auto
  shape-rendering: auto

.spinnerContainer  
  position: relative
  width: 75px
  height: 75px
  border-radius: 50%
  background-image: conic-gradient(from 180deg at 50% 50%, #306FD1 0deg, rgba(196, 196, 196, 0) 360deg)
  
.hollowCenterStyle  
  position: absolute
  background-color: #fff
  width: 55px
  height: 55px
  border-radius: 50%
  top: 10px
  left: 10px

@keyframes rotate-center
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
