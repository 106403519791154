@import "common/styles.module.sass"

.container
  cursor: pointer
  display: none
  > img
    width: 36px
    height: 36px
    border: 1px solid $LIGHT_BG_GREY
    border-radius: 50%
.sideDrawer
  display: none
  height: 100%
  background: white
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)
  position: fixed
  top: 0
  left: 0
  width: 70%
  max-width: 400px
  z-index: 200
  transform: translateX(-100%)
  transition: transform 0.3s ease-out
  padding: 22px 15px
.open
  transform: translateX(0)
.hamburgerMenu
  margin-bottom: 16px
@media (max-width: $breakpoint-lg)
  .container
    display: block
  .sideDrawer
    padding: 20px 8px 48px 8px
    display: block
