@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.imageWithTrustMark
  position: relative
  height: max-content
  justify-content: flex-end
  align-items: end
  > img
    border-radius: 14px
    width: max-content
  .imageContainer
    display: flex
    overflow: hidden
  .trustInfo
    position: absolute
    bottom: -2px
    right: -4px
.trustMark
  height: max-content
  position: relative
  display: flex
  justify-content: center
  align-items: center
  .trustLevel
    position: absolute
    margin-top: 2px
    margin-right: 1px
  .level
    position: absolute
    margin-right: -1px
    margin-top: 1.5px
  .textBase
    color: $WHITE
    margin: 0
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  .textStyleXXL
    @include DesktopUIXLMedium()
  .textStyleXL
    @include DesktopUIMMedium()
  .textStyleL
    @include Body2Medium()
  .textStyleM
    @include Body2Medium()
  .textStyleS
    @include Body3Medium()
  .textStyleXS
    @include DesktopUIXXSMedium()
  .textStyleXXS
    @include DesktopUIXXXSMedium()
  .trustedContact
    color: $WHITE
