@import "common/typography/styles.module.sass"

.wrapper
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  position: absolute
  left: 0
  top: 0
  gap: 40px
  padding: 0 20px
  .title
    max-width: 808px
    color: $TEXT_SECONDARY
    text-align: center
