@import "common/styles.module.sass"

.responsiveLayout
  width: 100%
  display: flex
  min-height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - 6px)
  grid-gap: 32px
  justify-content: center

.leftSection
  display: contents

.rightSection
  display: contents
  &.rightContentHidden
    display: none

.responsiveLayoutLeft
  padding: 24px 8px
  width: 100%
  max-width: 400px
  display: flex
  @include pageWrapper_V2 // TODO: Check and remove for V2 components
  &.rightContentHidden
    max-width: 808px
.responsiveLayoutRight
  padding: 24px
  @include pageWrapper_V2
  flex-grow: 1
  &.contentCenter
    display: flex
    align-items: center
    justify-content: center
.limitHeight
  .responsiveLayoutRight, .responsiveLayoutLeft
    height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - 6px)
.responsiveLayoutRight, .responsiveLayoutLeft
  min-height: 0

@media (max-width: $breakpoint-lg)
  .responsiveLayoutLeft
    width: 100%
    flex-grow: 1
  .responsiveLayoutRight, .responsiveLayoutLeft
    height: calc(100vh - 2 * #{$PADDING_BOTTOM_PAGE} - #{$NAVBAR_TABLET_HEIGHT})
    min-height: 0
    padding-bottom: calc(#{$NAVBAR_MOBILE_HEIGHT} + #{$NAVBAR_MOBILE_BOTTOM_DISTANCE})
    max-width: 100%
  .leftSection
    &.hideOnMobile
      display: none
  .rightSection
    &.hideOnMobile
      display: none

@media (max-width: $breakpoint-xs)
  .responsiveLayout
    height: 100vh
    min-height: 0
  .responsiveLayoutLeft, .responsiveLayoutRight
    padding: 20px 0
    height: 100vh
    border-radius: unset
  .responsiveLayoutRight
    &.contentCenter
      padding-bottom: 100px
    &.removeBorder
      border: none
      box-shadow: none
  .leftWithHeader
    padding-top: 0 !important
