@import "common/styles.module.sass"

.dropdownProfileSection
  display: flex
  flex-direction: column
  padding: 8px
  gap: 8px
  .profileHeader
    display: flex
    gap: 8px
    background: $BACKGROUND_LIGHT_01
    border-radius: 8px
    padding: 8px 4px
    cursor: pointer
    .imgContainer
      flex: none
    .profileDetails
      display: flex
      flex-direction: column
      gap: 4px
      max-width: 200px
      .profileName
        color: $TEXT_PRIMARY
        @include textTruncate(1)
      .jobName
        color: $TEXT_THIRTARY
        @include textTruncate(1)
  > button
    width: 100%
    padding: 8px 16px
    gap: 10px

.dropDownButton
  position: relative
  user-select: none
  padding: 8px 0
  .dropDownContainer
    position: absolute
    top: 70px
    background: #FFFFFF
    overflow: auto
    @include scrollbar
    width: 100%
    border-radius: 8px
    .dropDownItem
      height: 60px
      padding-left: 16px
      cursor: pointer
      transition: 0.1s
      display: flex
      align-items: center
      gap: 12px
      padding: 10px
      &:hover
        background: #f0f3f6
      &.disabled:hover
        background: unset
        cursor: default
      .title
        font-size: 15px
        font-weight: 500
        color: $GREY
  .isFullScreen
    z-index: 9999
    right: 0
    box-shadow: 0px 5px 8px 0px $POPUP_SHADOW
    border-radius: 8px
    max-height: 100vh
  .defaultStyles
    align-items: center
    cursor: pointer
    justify-content: center
  .button
    display: flex
    transition: 0.1s
    height: 100%
    &.active
      > svg
        &:first-child
          path
            fill: $BLUE !important
    .arrow
      svg
        margin-left: 2px
        margin-top: 4px
        transform: scale(0.7)
        path
          stroke: #CDD5E5 !important
          fill: #CDD5E5 !important
.border
  border-bottom: 0.5px solid $LIGHT_BG_GREY

.dropdownMenuStyles
  padding-top: 0

@media (max-width: $breakpoint-lg)
  .dropDownButton
    .button
      svg
        width: 22px
        height: 22px
    .dropDownContainer
      top: 0
