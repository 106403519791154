@import "common/styles.module.sass"

.wrapper
  display: flex
  flex-direction: column
  p
    @include fontSize14($WALKAWAY_GREY, 500)
    white-space: pre-line
  .buttons
    display: flex
    width: 100%
    justify-content: flex-end
    grid-gap: 16px
    margin-top: 32px
    button
      max-width: max-content
    .discard
      @include fontSize14($WHITE, 500)
    .cancel
      @include fontSize14($BLUE, 500)
