@import "common/styles.module.sass"
.button
  font-size: 16px
  height: 40px
  display: inline-flex
  align-items: center
  justify-content: center
  gap: 8px
  border: none
  outline: none
  border-radius: 8px
  color: $BLUE
  cursor: pointer
  transition: 0.2s
  background: none
  padding: 0 20px
  &:disabled
    opacity: 0.65
    cursor: auto
  &:hover
    border-color: $BLUE

.secondaryButton
  background: $WHITE
  color: $BLUE
  border: 1px solid $BLUE

.primaryButton
  background: $BLUE
  color: $WHITE
  border: none
