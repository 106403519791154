@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.dropdownMenu
  padding: 8px 0px
  display: grid
  gap: 4px
  border-radius: 8px
  background: #FFFFFF
  box-shadow: 0px 5px 8px 0px $POPUP_SHADOW
  z-index: 9999
.dropdownItem
  display: flex
  cursor: pointer
  padding: 4px 8px
  gap: 8px
  align-items: center
  color: $TEXT_PRIMARY
  &:hover
      background-color: $SHADES_PRIMARY_10
