@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

@mixin labelStyles()
  min-height: 24px
  position: absolute
  top: -9px
  left: 15px
  background: white
  padding: 0 3px
  color: $TEXT_SECONDARY
  &.invalidTitle
    color: $RED
  &.disabledTitle
    color: $Disabled_Dark

.hiddenSpan
  @include Body1Regular()
  @include textTruncate(1)

.locationsTitle
  color: $TEXT_SECONDARY
  position: absolute
  top: -10px
  left: 15px
  background: white
  padding: 0 3px
  &.errored
    color: $RED

.locationInputContainer
  display: flex
  align-items: center
  border: 1px solid $BORDER_REGULAR
  border-radius: 8px
  min-height: 56px
  padding: 7px 16px
  .title
    @include labelStyles()
  &.expandableInput
    gap: 8px 16px
  .expandableInput
    flex: 1
    width: unset
  &.errored
    border: 1px solid $RED
  &:focus
    border: 1px solid $BLUE
  .inputContainer
    display: flex
    flex-wrap: wrap
    gap: 16px
  input
    cursor: text
    @include Body1Regular()
    border: none !important
    outline: none
    padding: 0
    background-color: transparent
    width: auto
    height: unset
    &::placeholder
      color: $TEXT_THIRTARY
    &:focus
      border: none
    &.hasValue
      border: none
      background-color: $BACKGROUND_LIGHT_01
  .tag
    display: flex
    align-items: center
    background-color: $BACKGROUND_LIGHT_01
    border-radius: 4px
    padding: 4px 8px
  .tagTitle
    margin-right: 4px
    color: $TEXT_SECONDARY
  .deleteTag
    cursor: pointer
    align-items: center
    margin-left: 8px
    font-weight: bold
    display: flex
.expandableInputWrapperStyles
  border: none
  margin-top: 0 !important
  display: grid
  align-items: center
  &:focus
    border: none
  input
    height: 20px
.expandableInputStyles
  input
    @include Body1Regular()
    border: none !important
    outline: none
    padding: 4px
    background-color: transparent
    width: auto
    &::placeholder
      color: $TEXT_THIRTARY
    &:focus
      border: none
    &.hasValue
      border: none
      background-color: $BACKGROUND_LIGHT_01

.expandableInputWrapper
  position: relative
  .title
    @include labelStyles()
  .placeholderTitle
    @include Body1Regular()
    color: $TEXT_THIRTARY
    opacity: 0.6
    position: absolute
    left: 16px
    top: 16px
    z-index: 9
    user-select: none
    cursor: text
.expandableInput
  width: 100%

.input
  @include Body1Regular()
  color: $TEXT_SECONDARY
  width: 100%
  height: 56px
  border: 1px solid $BORDER_REGULAR
  padding: 10px 16px
  min-width: 100%
  outline: 0
  &:focus
    border: 1px solid $BLUE
  &.isInvalid
    border: 1px solid $RED
  &:disabled
    color: $Disabled_Dark
    background-color: $WHITE
  &::placeholder
    @include Body1Regular()
    padding-right: 3px
    color: $TEXT_THIRTARY
.popupMenu
  @include dropdownMenuContainer()
.selectElement
  cursor: pointer
.hideSelectElement
  background-image: none
.filledArrow, .closeIconStyles
  svg
    position: absolute
    right: 16px
    top: 14px
    cursor: pointer
  .withPlaceholderCloseIcon
    svg
      top: 21px
  .withPlaceholderArrow
    svg
      top: 16px
  .filledArrowOpened
    svg
      rotate: 180deg

@media (max-width: $breakpoint-sm)
  .popupMenu
    border-top: 1px solid $LIGHT_GRAY
