@import "common/typography/styles.module.sass"
.headerStyles
  padding: 0 !important

.shareContainer
  padding: 0 !important
  display: grid
  gap: 16px
.shareIconsContainer
  display: grid
  gap: 16px
  .shareIcons 
    display: flex
    justify-content: space-between
    color: $TEXT_TEXT_DARK

.shareTitle 
  color: $TEXT_TEXT_DARK

.iconWithName 
  display: flex
  flex-direction: column
  align-items: center
  cursor: pointer
  gap: 2px
