@import "common/styles.module.sass"

.iconStyles
  display: contents
  svg
    width: 100%
    min-height: 70px
    min-width: 70px

.imgStyles
  border-radius: 50%
  min-height: 70px
