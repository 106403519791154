@import "common/styles.module.sass"
@keyframes spin
  @include spinner
.wrapper
  .containerStyles
    padding-bottom: 32px

.emptySearchStateSwap
  > svg
    max-height: 120px

.otherProfileTrusts
  min-height: 260px

.loading
  & > svg
    animation: spin 2s linear infinite
.contacts
  @include contactsList()
  overflow: hidden
  gap: 16px
  .contact
    @include profileItem()
    cursor: pointer
    padding: 0
    &.cursorUnset
      cursor: unset
    .data
      display: flex
      gap: 10px
      justify-content: space-between
      width: 100%
      strong
        color: $DARK_BLUE
      > div
        display: block
        flex: 1
        .userName
          @include textTruncate(1)
          color: #222629
          margin-bottom: 4px
          width: fit-content
        .position
          color: #374957
          @include textTruncate(1)
          width: fit-content
        .tooltip
          border-radius: 4px
          padding: 4px 8px
          background-color: $DARK_GREY
          max-width: 463px
          word-wrap: break-word
          &::after
            background-color: $DARK_GREY
          &::before
            background-color: $DARK_GREY
            height: 6px
      .button
        border-radius: 8px
        min-width: 92px
        padding: 0 14px
      .trustButtonStyle
        background-color: $BLUE
      .trustingButtonStyle
        background-color: $WHITE_SMOKE_LIGHT
.imgContainer
  @include contactImgContainer()
.subtitle
  color: #222629
.searchFilters
  display: grid 
  gap: 16px
