@import "common/styles.module.sass"

.uploadButton
  display: flex
  cursor: pointer
  padding: 0
  .button
    border: none
.error
  @include fontSize14($ERROR_RED)
.modalChild
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  .sendButton
    cursor: pointer
  .content
    display: flex
    align-items: center
    .previewImage
      width: 80px
      height: 80px
      border-radius: 16px
      object-fit: cover
    .fileInfo
      margin-left: 18px
      .fileName
        max-width: 284px
        @include fontSize16($DARK_BLUE)
        @include textTruncate(1)
      .fileSize
        @include fontSize12($LIGHT_GRAY, 400)
        margin-top: 4px
