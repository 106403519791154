@import "common/styles.module.sass"

.pageContainer
  width: 100%
  display: flex
  gap: 24px
  flex-direction: column
  align-items: center
  margin-top: $MARGIN_TOP
.emptyHomeButton
  display: flex
  justify-content: center
  gap: 24px

.rightContent
  width: 100%
  max-width: 808px
.rightEmptyContent
  width: 100%
  max-width: 808px
  height: 100%
  position: relative
  .addButton
    position: absolute
    bottom: 24px
    right: 24px
    .dropdown
      top: -188px

.responsiveLayoutLeft
  background: none
  box-shadow: none
  height: unset !important
  padding: 0px

.responsiveLayoutRight
  overflow: auto
  background: none
  box-shadow: none
  min-width: calc(100% - 55% - 24px)
  &.rightContentHidden
    height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - 6px - 140px)
    padding: 0 7px 0 0
    padding-right: 8px
    @include scrollbar($scrollbarL, $TEXT_THIRTARY)

.searchAndFilters
  width: 100%
  display: grid
  gap: 16px
  &.rightContentHidden
    width: 100%
    max-width: 808px
.searchContainer
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  height: 46px
  gap: 40px
  & > div:first-of-type
    max-width: 550px
  &.rightContentHidden
    & > div:first-of-type
      max-width: unset

.addButton
  display: flex
  gap: 10px
  align-items: center

@media (max-width: $breakpoint-lg)
  .mobileHome
    display: block
    width: 100%
  .rightEmptyContent
    .addButton
      bottom: 92px
      right: 16px

@media (max-width: $breakpoint-xs)
  .pageContainer
    margin-top: 0
    gap: 0
  .rightEmptyContent
    .addButton
      position: fixed
  .mobileHome
    display: flex
    align-items: center
    gap: 20px
    padding: 20px
    background-color: $BLUE
    div
      margin: 0
      padding: 0
    input
      &:focus
        border: 1px solid $LIGHT_BG_GREY
  .responsiveLayoutRight
    &.rightContentHidden
      height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - 6px)
