@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.filters
  @include filters()

.filtersButton
  height: 36px
  padding: 6px 12px
  border-radius: 18px
  transition: background 300ms ease-out
  color: $TEXT_SECONDARY
  > div > div
    gap: 6px
    width: max-content
    @include DesktopUIMMedium()
  &.active
    color: $BLUE
    background: $SHADES_PRIMARY_20
  &.defaultFilter
    background: $WHITE
  &.lightFilter
    background: $BACKGROUND_LIGHT_01
