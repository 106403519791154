@import "common/styles.module.sass"

.messageBody
  display: flex
  align-items: flex-start
  flex-flow: column
  position: relative
  min-width: 242px
  audio
    position: absolute
  &.unsentMessageBody
    width: 100%
    audio
      width:100%
    .audioMessage
      background: $IVORY
      border-radius: 24px
      padding: 0 12px
      height: 46px
      .info
        flex-direction: unset
        gap: 14px
        padding-right: 14px
        align-items: center
        .timerForMessage
          padding-top: unset
          margin-bottom: unset
          .progressBar
            background: $BLUE
          .startProgressBar
            position: absolute
            display: block
            background: $BLUE
            width: 2px
            height: 6px
            margin-top: -2px
          .time
            background: $LIGHT_GREY_BORDER
        .audioDuration
          @include fontSize14($WALKAWAY_GREY, 500)
          > div
            width: max-content
  .audioMessage
    width: 100%
    display: flex
    .info
      width: 100%
      display: flex
      flex-direction: column
      justify-content: center
      padding-left: 14px
      .audioDuration
        @include fontSize14($WHITE, 500)
        > div
          width: max-content
        @include fontSize12($BLUE)
        display: flex
        gap: 10px
        align-items: center
        .point
          width: 6px
          height: 6px
          background-color: $BLUE
          border-radius: 50%
        &.audioDurationLight
          @include fontSize12($WHITE)
          .point
            background-color: $WHITE
    .playButton
      cursor: pointer
      display: flex
      align-items: center
    .timerForMessage
      padding-top: 6px
      width: 100%
      position: relative
      margin-bottom: 12px
      cursor: pointer
      .time
        position: absolute
        width: 100%
        height: 2px
        background: $LIGHT_GREY_BORDER
        border-radius: 2px
      .line
        background: $TRANSPARENT_WHITE
        border-radius: 2px
      .progressBar
        position: absolute
        height: 2px
        background: $BLUE
        border-radius: 2px
      .lightProgressBar
        background: $WHITE
      .startProgressBar
        display: none
  audio
    height: 0
    visibility: hidden
