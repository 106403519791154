@import "common/styles.module.sass"

.linksContainer
  .addLinkButton
    display: flex
    align-items: center
    justify-content: space-between
    cursor: pointer
    p
      color: $BACKGROUND_PRIMARY
    button
      padding: 12px
  .links
    display: grid
    gap: 24px
    .link
      .linkContent
        width: 100%
        .url
          display: flex
          align-items: center
          gap: 16px
          > div
            width: 100%
          input
            color: $BACKGROUND_PRIMARY
  .addLink
    margin-top: 20px
    display: flex
    justify-content: center
    align-items: center

.primaryButton
  float: right
