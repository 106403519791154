$DARK_BLUE: #030D21
$BLUE: #306FD1
$CAPRI_BLUE: #007AFF
$DISABLED_BLUE: #6499DC
$WALKAWAY_GREY: #606E8A
$BLACK: #000000
$MORE_TRANSPARENT_BLACK: rgba(0, 0, 0, 0.12)
$WHITE: #FFFFFF
$PURPLE: #EB0DE0
$LIGHT_BLUE: #F0F4FB
$TRANSPARENT_BLACK: rgba(0, 0, 0, 0.3)
$IVORY: #F8FAFF
$DARKER_IVORY: #F2F5FA
$BOX_SHADOW: 0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08)
$GREY_BORDER_COLOR: rgba(61, 61, 61, 0.3)
$GREY: #8B98B0
$LIGHT_GRAY: #CDD5E6
$GREY_BORDER: linear-gradient(0deg, #E6ECF4, #E6ECF4), rgba(255, 255, 255, 0.7)
$DELICATE_GRAY: #CDD5E5
$ERROR_RED: #D65555
$LIGHTER_GRAY: #E6ECF4
$GRAY_DISABLED: #E3E3E3
$TRUST_BLUE: #0A64BC
$LINE_GREY: #e0e7f0
$BOLD_GREY: #586174
$LIGHT_BG_GREY: #D7DFED
$PALE_BLOSSOM: #FDE7FC
$DARK_GREY: #3D3D3D
$RED: #F90606
$REQUIRED_RED: #EB5757
$TRANSPARENT_WHITE: rgba(255, 255, 255, 0.4)
$TRANSPARENT_BORDER: rgba(96, 110, 138, 0.2)
$ORANGE: #FF3B30
$LIGHT_GREY_BORDER: #BAC3D4
$GREY_GRADIENT: linear-gradient(0deg, #C9CCD3, #C9CCD3)
$GRADIENT_BLACK: linear-gradient(45deg, transparent 50%, #16161D 50%), linear-gradient(135deg, #16161D 50%, transparent 50%)
$POSITION_SELECT_ITEM: calc(100% - 22px) calc(1em + 6px), calc(100% - 14px) calc(1em + 6px), calc(100% - 2.5em) 0.5em
$POSITION_SELECT_ITEM_MIN: calc(100% - 22px) calc(1em + 2px), calc(100% - 14px) calc(1em + 2px), calc(100% - 2.5em) 0.5em
$GREY_BACKGROUND: rgba(48, 111, 209, 0.05)
$DELICATE_GREY: #e5ecf4
$WHITE_SMOKE: #F0F0F0
$GREEN: #0EB200
$LIGHT_PURPLE: #9747FF
$WHITE_SMOKE_LIGHT: #EFEFEF
$WHITE_SMOKE_LIGHT_TRANSPARENT:  rgba(239, 239, 239, 0.6)
$GREY_SHADOW: #E9EBF0
$BOTTOM_BORDER: #E0E0E0

$GROUP_CHAT_HEADER_HEIGHT: 188px
$CHAT_HEADER_HEIGHT: 82px
$CHAT_INPUT_HEIGHT: 98px
$CHAT_INPUT_REPLIED_MESSAGE_HEIGHT: 157px
$HOME_HEADER: 119px
$NAVBAR_MOBILE_HEIGHT: 60px
$NAVBAR_MOBILE_BOTTOM_DISTANCE: 34px
$NAVBAR_HEIGHT: 86px
$PADDING_BOTTOM_PAGE: 24px
$MARGIN_BOTTOM: 20px
$MARGIN_TOP: 20px
$NAVBAR_TABLET_HEIGHT: 88px
$NAVBAR_MOBILE_HEIGHT_HOME_PAGE: 125px
$HEIGHT_BREADCRUMB: 23px
$HOME_PAGE_NAVBAR_MOBILE: 109px
$FOOTER_HEIGHT: 105px

$MAX_Z_INDEX: 999999

$breakpoint-xxs: 494px
$breakpoint-xs: 639px
$breakpoint-sm: 767px
$breakpoint-md: 991px
$breakpoint-lg: 1023px
$breakpoint-xl: 1199px
$breakpoint-xxl: 1220px

$scrollbarS: 7px
$scrollbarM: 12px
$scrollbarL: 16px

@mixin scrollbar($width: $scrollbarS, $thumb: rgba(48, 111, 209, 0.2), $track: rgba(235, 238, 243, 1))
  @media (min-width: $breakpoint-xs)
    &::-webkit-scrollbar
      width: $width

    &::-webkit-scrollbar-track
      background: $track
      border-radius: 20px

    &::-webkit-scrollbar-thumb
      background: $thumb
      border-radius: 20px

@mixin textTruncate($linesCount: 1)
  display: -webkit-box
  -webkit-line-clamp: $linesCount
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  word-break: break-all

@mixin row ($gap: 8px)
  display: flex
  align-items: center
  grid-gap: $gap

@mixin standardText
  letter-spacing: 0.3px
  line-height: 1.5
@mixin fontSize10 ($color, $font-weight: 400)
  @include standardText
  font-size: 10px
  color: $color
  font-weight: $font-weight
@mixin fontSize12 ($color, $font-weight: 400)
  @include standardText
  font-size: 12px
  color: $color
  font-weight: $font-weight
@mixin fontSize14 ($color, $font-weight: 400)
  @include standardText
  font-size: 14px
  color: $color
  font-weight: $font-weight
@mixin fontSize16 ($color, $font-weight: 400)
  @include standardText
  font-size: 16px
  color: $color
  font-weight: $font-weight
@mixin fontSize18 ($color, $font-weight: 400)
  @include standardText
  font-size: 18px
  color: $color
  font-weight: $font-weight
@mixin fontSize20 ($color, $font-weight: 400)
  @include standardText
  font-size: 20px
  color: $color
  font-weight: $font-weight
@mixin fontSize22 ($color, $font-weight: 400)
  @include standardText
  font-size: 22px
  color: $color
  font-weight: $font-weight
@mixin fontSize24 ($color, $font-weight: 400)
  @include standardText
  font-size: 24px
  color: $color
  font-weight: $font-weight
@mixin fontSize26 ($color, $font-weight: 400)
  @include standardText
  font-size: 26px
  color: $color
  font-weight: $font-weight

@mixin pageWrapper()
  display: flex
  flex-direction: column
  border-radius: 12px
  box-shadow: $BOX_SHADOW
  background: $WHITE

@mixin pageWrapper_V2()
  display: flex
  flex-direction: column
  border-radius: 8px

@mixin invitIconeStyles()
  cursor: pointer
  width: 64px
  height: 64px
  background-color: $BLUE
  border-radius: 100%
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0px 4px 4px 0px #00000040

@mixin tag()
  @include fontSize14($DARK_BLUE, 500)
  line-height: 1.3
  padding: 5px 16px
  border: 1px solid $TRANSPARENT_BORDER
  border-radius: 15px
  white-space: nowrap

@mixin heightScrollableComponentParent()
  height: calc(100vh - #{$PADDING_BOTTOM_PAGE} -  #{$NAVBAR_MOBILE_HEIGHT} - #{$NAVBAR_MOBILE_BOTTOM_DISTANCE})
@mixin heightComponentForDesktop()
  height: calc(100vh - #{$NAVBAR_HEIGHT} -  4 * #{$PADDING_BOTTOM_PAGE})
@mixin heightHomeForMobile()
  height: calc(100vh - 2 * #{$PADDING_BOTTOM_PAGE} -  #{$NAVBAR_MOBILE_HEIGHT} - #{$NAVBAR_MOBILE_BOTTOM_DISTANCE} - #{$HEIGHT_BREADCRUMB} - 30px)

@mixin spinner()
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@mixin blinkAnimation()
  0%
    opacity: 0
  50%
    opacity: 0.8
  100%
    opacity: 0

@mixin popupShadow()
  box-shadow: 0px 6px 14px rgba(24, 39, 75, 0.12), 0px 10px 32px rgba(24, 39, 75, 0.1)
  transition: opacity 0.5s linear
  background: $WHITE
  backdrop-filter: blur(54.3656px)

@mixin tooltip ($color: $DARK_GREY, $bgColor: $WHITE, $margin: 0)
  @include fontSize14($color, 500)
  border-radius: 4px
  padding: 12px 18px
  background-color: $bgColor
  max-width: 463px
  box-shadow: 0px 6px 14px rgba(24, 39, 75, 0.02), 0px 10px 32px rgba(24, 39, 75, 0.1)
  word-wrap: break-word
  opacity: 1
  margin: $margin
  height: unset
  &::after
    background-color: $bgColor
  &::before
    background-color: $bgColor
    height: 6px

@mixin bone-skeleton
  background: linear-gradient(90deg, $BACKGROUND_LIGHT_BLUE 25%, rgba(255, 255, 255, 0.074) 50%, $BACKGROUND_LIGHT_BLUE 75%)
  background-size: 800px 100%
  animation: bone-skeleton 2.5s infinite linear
@keyframes bone-skeleton
  0%
    background-position: -800px 0
  100%
    background-position: 800px 0

// Global
$BACKGROUND_PRIMARY: #306FD1
$BACKGROUND_PRIMARY_ACTIVE: #1D5DBF
$BACKGROUND_PURPLE_ACTIVE: #7B3DCC
$BACKGROUND_GREEN_ACTIVE: #1A990F
$BASE_WHITE: #FFFFFF

// Background Colors
$BACKGROUND_PRIMARY_HOVER: #588DE1
$BACKGROUND_PURPLE_HOVER: #B47AFF
$BACKGROUND_GREEN_HOVER: #47C73C
$BACKGROUND_LIGHT_01: #F5F8FF
$BACKGROUND_LIGHT_01_HOVER: #E1E3E7
$BACKGROUND_LIGHT_01_ACTIVE: #CDD2DB
$BACKGROUND_LIGHT_BLUE: #E5ECF8
$BORDER_REGULAR: #C7CDD9
$GRAY-TOGGLE: #D1D5DB
$POPUP_SHADOW: #0000001A

// Text
$TEXT_PRIMARY: #222629
$TEXT_SECONDARY: #374957
$TEXT_THIRTARY: #868EA1
$TEXT_TEXT_DARK: #181B22

// Shades
$SHADES_PRIMARY_3: #306FD108
$SHADES_PRIMARY_10: #306FD11A
$SHADES_PURPLE_10: #9747FF1A
$SHADES_PRIMARY_20: #306FD133
$SHADES_PRIMARY_30: #306FD14D
$SHADES_THIRTARY_10: #5C62721A
$SHADES_WHITE_10: #FFFFFF1A
$SHADES_WHITE_20: #FFFFFF33

// States
$Disabled_Dark: #CDD4D8
$Disabled_White: #F9F9FA

// Accent Colors
$GLOBAL_ACCENT_1: #0EB200
$GLOBAL_ACCENT_2: #9747FF
$GLOBAL_ACCENT_3: #E32020

// Components
// Profile
@mixin profileLayout()
  max-width: 664px
  padding: 0
  margin-top: $MARGIN_TOP
  width: 100%
@mixin profileLayoutContent()
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%
@mixin profileInfoContainer()
  width: 100%
  padding: 48px 16px 48px 24px
  border-radius: 16px
  background: $WHITE
  gap: 32px
  display: flex
  flex-direction: column

@mixin contactInfo()
  max-width: 100%
  word-break: break-all
  display: grid
  gap: 4px
  min-height: 156px
  justify-content: center

@mixin trustedUsers()
  display: flex
  flex-wrap: wrap
  gap: 8px
  color: $DARK_BLUE
  align-items: center
  cursor: pointer
  width: fit-content

@mixin profileLinksWrapper()
  display: flex
  justify-content: center
  gap: 32px

@mixin tabsContainer()
  display: flex
  gap: 8px
  width: 100%
@mixin tab()
  width: 100%
  height: 88px
  border-radius: 8px
  background: $WHITE
  color: $TEXT_SECONDARY
  padding: 12px 0
  border-radius: 16px
  display: grid
  align-items: center
  justify-items: center
  gap: 8px
  cursor: pointer

@mixin aboutContainer()
  width: 100%
  display: grid
  gap: 8px
@mixin listItemContent()
  width: 100%
  display: grid
  gap: 8px
  padding: 16px
  border-radius: 16px
  background: $WHITE
  align-items: center
  word-break: break-word
@mixin listItemHeader()
  display: flex
  justify-content: space-between
  height: 32px
  align-items: center

// Network
@mixin listContainer()
  display: flex
  flex-direction: column
  height: 100%
  overflow-x: hidden
  overflow-y: auto
  gap: 24px
  padding-right: 16px
  @include scrollbar($scrollbarL)
  overflow-anchor: none

@mixin contactsContent()
  width: 100%
  display: grid
  gap: 20px
@mixin contactsList()
  width: 100%
  display: flex
  flex-direction: column
  gap: 8px
  overflow-x: hidden
  overflow-y: auto
  @include scrollbar($scrollbarS)
@mixin profileItem()
  width: 100%
  display: flex
  align-items: center
  padding: 8px
  gap: 8px
@mixin profileContent()
  display: grid
  align-items: center
  width: 100%
  flex: 1
@mixin contactImgContainer()
  flex: none

// Cards
@mixin cardsContainer()
  width: 100%
  display: flex
  gap: 24px
  flex-direction: column
  align-items: center
  margin-top: $MARGIN_TOP
  padding: 0

@mixin cardsList()
  display: flex
  flex-direction: column
  gap: 32px
  width: 100%
  max-width: 808px

// Network
@mixin networkLayoutRight()
  max-width: 712px
  width: 100%
  box-shadow: none
  background: $WHITE
  border-radius: 20px
  padding: 36px 32px
  margin-top: $MARGIN_TOP
  height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - #{$MARGIN_TOP}  - 6px)

@mixin networkContainer()
  position: relative
  display: flex
  flex-direction: column
  overflow: hidden
  @include scrollbar
  overflow-anchor: none
  height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - #{$MARGIN_TOP} - 6px)
  width: unset
  gap: 32px

@mixin networkHeaderNavBar()
  display: grid
  padding: 0
  gap: 16px

@mixin filters()
  display: flex
  min-height: 36px
  gap: 8px

// Group
@mixin groupLayout()
  margin-top: $MARGIN_TOP
  background: $WHITE
  max-width: 712px
  width: 100%
  border-radius: 20px
  padding: 36px 32px
  margin-top: 20px
  min-height: calc(100vh - #{$FOOTER_HEIGHT} - #{$MARGIN_TOP} - #{$NAVBAR_HEIGHT} - 6px - 20px)
@mixin groupLayoutContent()
  display: flex
  flex-direction: column
  align-items: center
  gap: 32px
@mixin groupParticipantsContainer()
  display: grid
  gap: 8px
  justify-content: center
  justify-items: center
@mixin groupMembersContainer()
  display: grid
  gap: 20px
  width: 100%  
@mixin groupAvatar()
  flex: none
@mixin groupInfo()
  display: flex
  gap: 4px
  flex: 1
@mixin groupActions()
  display: flex
  gap: 16px
  width: 100%
  justify-content: flex-end 

// Settings
@mixin privacyPolicyLayout()
  max-width: 664px
  width: 100%
  height: 100%
  background: $WHITE
  padding: 32px
  height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - #{$MARGIN_TOP} - 6px)

@mixin privacyPolicyLayoutContent()
  width: 100%
  display: flex
  flex-direction: column
  gap: 24px
  padding: 16px
  overflow-x: hidden
  overflow-y: auto
  max-width: 664px
  background: $WHITE
  height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - #{$MARGIN_TOP} - 6px)
  @include scrollbar($scrollbarL)

// Dropdown
@mixin dropdownMenuContainer()
  z-index: 9999
  position: absolute
  width: 99%
  background-color: $WHITE
  padding: 16px 0px 8px 0
  border-radius: 0 0 8px 8px
  box-shadow: 0px 5px 8px 0px $POPUP_SHADOW

@mixin popupContent()
  overflow-x: hidden
  overflow-y: auto
  width: 100% 
  max-height: 40vh
  @include scrollbar

@mixin popupContentOption()
  display: flex
  gap: 8px
  cursor: pointer
  padding: 8px 16px
  border: 0
  text-align: start
  align-items: center
  background: none
  width: 100%
  color: $TEXT_THIRTARY
