@import "common/styles.module.sass"

.dropDownContainer
  height: 46px
  position: relative
.dropdownMenuStyles
  margin: 19px 0px 0px -65px
  box-shadow: 0px 5px 8px 0px $POPUP_SHADOW
  z-index: 1
  position: relative
  > div
    height: 28px
    padding: 4px 8px
    &:hover
      &:nth-child(odd)
        background-color: $SHADES_PRIMARY_10
      &:nth-child(even)
        background-color: $SHADES_PURPLE_10
