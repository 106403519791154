@import "common/styles.module.sass"

.errorMsgModalContent
  display: grid
  gap: 16px
  justify-items: center
  .title
    color: $TEXT_PRIMARY
    white-space: pre-line
    text-align: center
  .body
    color: $TEXT_THIRTARY
    white-space: pre-line
    overflow-wrap: break-word
    text-align: center
  .customDescription
    color: $WALKAWAY_GREY
.errorModalFooterStyles
  justify-content: center
  > button
    width: 100%
    
@media (max-width: $breakpoint-sm)
  .errorMsgModalContent
    padding: 0
    > svg
      margin: 4px 0
