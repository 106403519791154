@import "common/styles.module.sass"

.emptyContainer
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  overflow-y: auto
  gap: 32px
  @include scrollbar
  .emptyContainerText
    display: grid
    gap: 8px
  .title
    max-width: 650px
    color: $TEXT_SECONDARY
    text-align: center
  .description
    max-width: 650px
    color: $TEXT_THIRTARY
    text-align: center

@media (max-width: $breakpoint-lg)
  .emptyContainer
    overflow: initial
    .description
      max-width: 327px
