@import "common/styles.module.sass"

.buttonContainer
  margin-top: 24px
  display: flex
  justify-content: flex-end
  gap: 16px
  border-radius: 8px
.photoContainer
  border-radius: 12px
  width: max-content
  position: relative
  .addPhoto
    cursor: pointer
    position: absolute
    bottom: -7px
    right: -7px
  img
    border-radius: 12px
  svg
    margin-right: -3px
  .personIcon
    background-image: $GREY_GRADIENT
    display: flex
    align-items: center
    justify-content: center
    width: 186px
    height: 186px
    border-radius: 100px
.photoContainerDisabled
  cursor: default
.inputFile
  display: none
.uploadTitle
  color: $BLUE
  text-align: center
  cursor: pointer
@media (max-width: $breakpoint-xs)
  .buttonContainer
    flex-direction: column
