@import "common/styles.module.sass"

.baseImageWrapper
  width: max-content
  height: max-content
.visible
  display: block
  width: 100%
  object-fit: cover
.hidden
  display: none
.skeleton
  width: 100%
  height: 100%
  position: relative
  overflow: hidden
.backgroundBlossom
  background: $PALE_BLOSSOM
.personIcon
  background-image: $GREY_GRADIENT
  display: flex
  align-items: center
  justify-content: center
.skeleton::before
  content: ''
  display: block
  position: absolute
  left: -200px
  top: 0
  height: 100%
  width: 100%
  background: linear-gradient(to right, transparent 0%, #F5F5F5 50%, transparent 100%)

  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite
@keyframes load
  from
    left: -200px
  to
    left: 100%
