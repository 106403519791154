@import "common/styles.module.sass"

.responsiveImage
  width: 100%
  height: auto
  max-width: 560px

.searchAndFilters
  width: 100%
  display: grid
  gap: 16px
  &.rightContentHidden
    width: 100%
    max-width: 808px

.headerContainer
  display: flex
  gap: 8px
  align-items: center
  > svg
    height: 24px
    width: 24px
    border: 1.5px
    cursor: pointer

.searchContainer
  display: flex
  justify-content: space-between
  align-items: center
  height: 46px
  gap: 40px
  & > div:first-of-type
    max-width: 50%
  &.rightContentHidden
    & > div:first-of-type
      max-width: unset

.addButton
  display: flex
  gap: 10px
  align-items: center

      
.dropDownContainer
  height: 46px
  position: relative

.emptyContainer
  align-items: center
  flex-direction: column
  gap: 48px
  display: flex
  width: 100%
  height: 50vh
  svg
    width: 100%
    max-width: 586px
    height: auto
  .emptyListingStateContent
    display: grid
    gap: 8px
    .emptyListingDescription
      max-width: 400px
      color: $TEXT_THIRTARY 
      text-align: center

.responsiveImage
  width: 100%
  height: auto
  max-width: 586px

.cardHeaderTitle
  display: flex
  justify-content: space-between

.resumeHeaderText
  padding: 1px 4px 1px 4px
  border-radius: 4px
  background: rgba(14, 178, 0, 0.10)
  color: $GLOBAL_ACCENT_1

.hiringHeaderText
  padding: 1px 4px 1px 4px
  border-radius: 4px
  background: rgba(151, 71, 255, 0.10)
  color: $GLOBAL_ACCENT_2

.headerText
  display: flex
  align-items: center
  gap: 10px
  text-transform: uppercase
  justify-content: space-between

.listingTitle
  color: $TEXT_PRIMARY

@media (max-width: $breakpoint-lg)
  .mobileHome
    display: block
    width: 100%
  .emptyHomeContainer
    overflow-y: auto !important


@media (max-width: $breakpoint-xs)
  .mobileHome
    display: flex
    align-items: center
    gap: 20px
    padding: 20px
    background-color: $BLUE
    div
      margin: 0
      padding: 0
    input
      &:focus
        border: 1px solid $LIGHT_BG_GREY
