@import "common/styles.module.sass"

.wrapper
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  background-color: $BACKGROUND_LIGHT_01
  .centeredLogo
    padding: 16px 0
    background: #fff
    width: 100%
    display: flex
    justify-content: center
    min-height: 80px 
    img
      width: 118px
  .content
    height: calc(100vh - #{$NAVBAR_HEIGHT})
    overflow-y: auto
    @include scrollbar($scrollbarL)
.container
  width: 100%
  max-width: 1280px
  padding: 0 16px
  display: flex
  margin: 20px auto
  transition: 0.2s
  @include scrollbar

@media (max-width: $breakpoint-lg)
  .wrapper
    .content
      padding: 0 24px
      height: calc(100vh - 44px)
      margin-bottom: 60px
@media (max-width: $breakpoint-xs)
  .wrapper
    .content
      padding: unset
      margin-bottom: unset
      margin-top: unset
      overflow-y: unset
  .container
    margin: unset
    padding: 0
