@import "common/styles.module.sass"

.toggleSwitch
  width: 44px
  height: 24px
  background-color: $GRAY-TOGGLE
  border-radius: 25px
  position: relative
  cursor: pointer
  transition: background-color 0.3s
  &.checked
    background-color: $BACKGROUND_PRIMARY
  &.disabled
    background-color: $GRAY-TOGGLE
    cursor: not-allowed

.switchThumb
  width: 20px
  height: 20px
  background-color: $WHITE
  border-radius: 40px
  position: absolute
  top: 2px
  left: 2px
  transition: transform 0.3s

.toggleSwitch.checked .switchThumb
  transform: translateX(20px)
