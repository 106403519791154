@import "common/styles.module.sass"

.emptySearchState
  align-items: center
  flex-direction: column
  gap: 32px
  display: flex
  width: 100%
  height: 100%
  svg
    width: 100%
    max-width: 586px
    height: auto
  .emptySearchStateContent
    display: grid
    gap: 8px
    height: 78px
    p:first-child
      text-align: center
      color: $TEXT_SECONDARY
    p:nth-child(2)
      text-align: center
      color: $TEXT_THIRTARY
