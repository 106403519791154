@import "common/styles.module.sass"

.responsiveImage
  height: auto
  max-width: 560px
  padding-top: 70px

.searchAndFilters
  width: 100%
  display: grid
  gap: 16px
  &.rightContentHidden
    width: 100%
    max-width: 808px

.archiveContainerHeader
  display: flex-start
  width: 808px

@media (max-width: $breakpoint-lg)
  .mobileHome
    display: block
    width: 100%

@media (max-width: $breakpoint-xs)
  .mobileHome
    display: flex-start
    gap: 20px
    padding: 20px
    background-color: $BLUE
    div
      margin: 0
      padding: 0
    input
      &:focus
        border: 1px solid $LIGHT_BG_GREY
.emptyContainer
  align-items: center
  flex-direction: column
  gap: 48px
  display: flex
  width: 100%
  height: 50vh
  svg
    width: 100%
    max-width: 586px
    height: auto
  .emptyArchiveStateContent
    display: grid
    gap: 8px
    .emptyArchiveDescription
      max-width: 400px
      color: $TEXT_THIRTARY 
      text-align: center

.responsiveImage
  width: 100%
  height: auto
  max-width: 586px

.archiveTitle
  color: $TEXT_PRIMARY
