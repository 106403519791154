@import "common/styles.module.sass"

.groupItem
  @include profileItem()
  cursor: pointer
.groupItemHover
  &:hover
    background: $SHADES_PRIMARY_10
    border-radius: 8px

.groupContent
  @include groupInfo()
  .name
    color: $TEXT_PRIMARY
    @include textTruncate(1)
  .groupData
    color: $TEXT_THIRTARY
    @include textTruncate(1)

.buttonContainer
  min-width: 56px
  height: 48px
  justify-content: center
  align-items: center
  display: flex
.requestButtons
  justify-content: center
  align-items: center
  display: flex
  > button
    background: none
