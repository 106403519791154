@import "common/typography/styles.module.sass"
.shareLinkContainer 
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 8px
  position: relative
  .loadingIcon
    svg
      position: inherit
      width: 20px
      height: 20px
      margin-top: 10px
  span
    padding: 0 2px

.copyText
  display: flex
  gap: 10px

.copiedText 
  color: $BLUE

.linkContainer
  display: flex
  align-items: center
  background: WHITE
  gap: 8px
  height: 52px
  max-width: 650px
  border-radius: 8px
  width: 100%
  padding: 6px 12px
  span
    padding: 0 2px

.shareLinkInput
  flex: 1
  padding: 8px
  border: 1px solid #ccc
  border-radius: 8px
  outline: none
  overflow-x: auto
  white-space: nowrap
  font-size: 16px
  background-color: inherit
  border: none
  @include textTruncate(1)
  @include DesktopUIMMedium()
  color: $TEXT_SECONDARY

.shareLink
  @include textTruncate(1)
  color: $TEXT_SECONDARY
