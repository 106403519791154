@import "common/styles.module.sass"

.toastContainer
  position: fixed
  top: 100px
  right: 40px
  display: flex
  flex-direction: column
  gap: 16px
  max-height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - 6px)
  min-height: 70px
  padding: 0 10px 10px 0
  @include scrollbar
  overflow-y: auto
.topPriority
  z-index: 9999
