@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

@keyframes blink
  @include blinkAnimation

.container
  min-height: 57px
  width: 100%
  padding: 8px 0 32px 0
  order: 3
  transition: 0.1s
  .replyTo
    border-top: 1px solid $LIGHT_GRAY
    display: flex
    grid-gap: 8px
    padding: 10px 0
    & > svg
      margin-right: 4px
    .previewImage
      width: 40px
      height: 40px
      border-radius: 8px
      object-fit: cover
    .content
      width: calc(100% - 35px)
      & > p
        @include textTruncate
        &.user
          @include fontSize16($DARK_BLUE, 600)
          & > svg
            margin-right: 12px
        &.message
          @include fontSize16($DARK_BLUE, 400)
    .closeIcon
      margin-left: auto
      cursor: pointer
  .errorMsg
    @include fontSize12($GREY)
    margin-bottom: 4px
    display: flex
    justify-content: flex-end
  .inputContent
    display: flex
    width: 100%
    align-items: flex-end
    form
      width: 100%
      display: flex
      gap: 16px
      align-items: flex-start
      .input
        display: flex
        gap: 16px
        width: 100%
      .recordingContainer
        display: flex
        color: $GREY
        justify-content: space-between
        width: 100%
        gap: 16px
        align-items: center
        .recordedAudio
          width: 100%
          max-width: calc(100% - 70px)
          height: 20px
          &::-webkit-media-controls-panel,
          &::-webkit-media-controls-play-button
            background-color: $WHITE
        .recStart
          padding-left: 6px
          display: flex
          gap: 10px
          align-items: center
          width: 100%
          background: $BLUE
          justify-content: center
          padding: 12px 14px
          border-radius: 24px
          .stopRecording
            background-color: $ORANGE
            animation: blink 1s infinite
            cursor: pointer
            width: 12px
            height: 12px
          .timer
            @include fontSize14 ($WHITE, 500)
        .cancel
          cursor: pointer
          display: flex
          align-items: center
    .inputAndAudioField
      width: 100%
      position: relative
      display: flex
      width: 100%
      .textInputContainer
        display: flex
        flex-direction: column
        flex-grow: 1
        .textInput
          @include DesktopUIMRegular()
          border: none
          width: 100%
          height: 46px
          background: $BACKGROUND_LIGHT_01
          border-radius: 24px
          padding: 12px
          border: solid 1px transparent
          color: $TEXT_TEXT_DARK
          &::placeholder
            color: $TEXT_THIRTARY
          &:focus
            outline: none
        .counter
          @include fontSize14($LIGHT_GRAY, 500)
          margin-top: 4px
          text-align: right
          padding-right: 8px
    .sendMessageButton
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      background: none
      border: none
      svg
        path
          transition: 0.2s
      &:hover
        svg
          path
            fill: #8798cf
  .audioRecorder
    cursor: pointer
    display: flex
    position: absolute
    right: 10px
    top: 50%
    transform: translateY(-50%)

@media (max-width: $breakpoint-lg)
  .container
    padding: 0 30px

@media (max-width: $breakpoint-xs)
  .container
    padding: 18px 8px 0 8px
    border-top: 1px solid $DARKER_IVORY
    border-radius: 20px 20px 0px 0px
    margin-bottom: 34px
