@import "common/typography/styles.module.sass"

.skeletonContainer
  @include cardsContainer()
  &.noTopSpacing
    margin-top: 0

.homeSkeletonWrapper, .listingSkeletonWrapper, .archiveSkeletonWrapper
  @include cardsList()
  .skeletonCard
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    .skeletonHeaderText
      color: $TEXT_THIRTARY

    
.networkLayoutRight
  @include networkLayoutRight()
  &.noTopSpacing
    margin-top: 0
    padding: 0
.networkSkeletonWrapper
  @include networkContainer()
  .networkSkeletonHeader
    @include networkHeaderNavBar()
    .networkSkeletonFilters
      @include filters()
  .listContainer
    @include listContainer()
    .contactsContent
      @include contactsContent()

.chatSkeletonWrapper, .chatSkeletonRightContent
  display: grid
  overflow: hidden
  height: calc(100vh - #{$FOOTER_HEIGHT} - #{$MARGIN_BOTTOM} - #{$NAVBAR_HEIGHT} - 30px)
  .chatMessages
    width: 100%
    display: grid
    gap: 8px
    height: auto
    overflow-x: hidden
    overflow-y: auto
    padding: 32px 18px
    @include scrollbar($scrollbarS)
    .ownerMessage
      display: flex
      justify-content: flex-end
    .otherOwnerMessage
      display: flex
      justify-content: flex-start
      align-items: flex-end
      gap: 8px
    .imgContainer
      cursor: pointer
      &.cursorNone
        cursor: default

.chatSkeletonWrapper
  gap: 16px

.conversationLayoutRight
  background: $WHITE
  border-radius: 20px
  margin-top: $MARGIN_TOP
  height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - #{$MARGIN_TOP} - 6px)
  width: 816px
  box-shadow: 0px 5px 8px 0px #0000001A
  &.hasChatRightContent
    margin: 0
    padding: 0
    height: unset
    background: none
    box-shadow: none

.profileLayoutRight
  @include profileLayout()
  &.noTopSpacing
    margin-top: 0
    padding: 0
  .profileSkeletonWrapper
    @include profileLayoutContent()
    .infoContainer
      @include profileInfoContainer()
      .infoActions
        display: flex
        gap: 32px
        .personalInfo
          width: 100%
          display: flex
          align-items: center
          gap: 24px
          .contactInfo
            @include contactInfo()
            .trustedUsersContainer
              @include trustedUsers()
        .actions
          margin: 0 0 auto auto
      .profileLinksWrapper
        @include profileLinksWrapper()
    .tabsContainer
      @include tabsContainer()
      .tab
        @include tab()
    .aboutGroupContainer
      @include listItemContent()
      min-height: 108px
    .aboutContainer
      @include aboutContainer()
      .listItemContent
        @include listItemContent()
        min-height: 112px
        .listItemHeader
          @include listItemHeader()

.contactsList
  @include contactsList() 
.contact
  @include profileItem()
  min-height: 72px
  .avatar
    @include contactImgContainer()
  .textContainer
    @include profileContent()
    height: 40px
    .text
      display: flex
      justify-content: space-between
    .action
      margin-left: auto

.groupLayoutRight
  @include groupLayout()  
.groupSkeletonWrapper
  @include groupLayoutContent()
  .groupParticipantInfo
    @include groupParticipantsContainer()
    min-height: 130px
  .groupMemberList
    @include groupMembersContainer()
    .groupMember
      @include profileItem()
      min-height: 72px
      .avatar
        @include groupAvatar()
      .info
        @include profileContent()
        gap: 10px
      .actions
        @include groupActions()

.privacyLayoutRight
  @include privacyPolicyLayout()
  .privacySkeletonWrapper, .termsSkeletonWrapper
    @include privacyPolicyLayoutContent()
    padding: 0 16px
    &.inSinglePage
      max-width: 816px
    .privacyHeader
      display: grid
      gap: 8px
    .termsHeader
      display: grid
      gap: 16px
    .headerItem
      display: grid
      gap: 8px
  &.inSinglePage
    max-width: 816px
  .termsSkeletonWrapper
    gap: 28px

.dropdownMenuSkeleton
  @include dropdownMenuContainer()
  .popup
    @include popupContent()
    .option
      @include popupContentOption()

@media (max-width: $breakpoint-lg)
  .responsiveLayoutOnMobile
    height: calc(100vh - 2 * #{$PADDING_BOTTOM_PAGE} - #{$NAVBAR_TABLET_HEIGHT})

@media (max-width: $breakpoint-xs)
  .chatSkeletonRightContent
    height: calc(100vh - 20px)

@media (max-width: $breakpoint-xxs)
  .personalInfo
    flex-direction: column
  .contactInfo
    justify-items: center
