@import "common/styles.module.sass"

.profileContent
  @include profileContent()
  cursor: pointer
  &.cursorUnset
    cursor: unset
  > div
    strong
      color: $DARK_BLUE
  .name
    color: $TEXT_PRIMARY
  .jobTitle
    color: $TEXT_THIRTARY
  .name, .jobTitle
    @include textTruncate(1)
.profileItem
  @include profileItem()
  .button
    border-radius: 8px
    border: none
    cursor: pointer
    min-width: 92px
    padding: 0 14px
  .trustButtonStyle
    background-color: $BLUE
    color: $WHITE
  .trustingButtonStyle
    background-color: $WHITE_SMOKE_LIGHT
    color: $DARK_BLUE
.checkIcon
  position: absolute
  right: 10px
  top: 48px

.imgContainer
  @include contactImgContainer()
  cursor: pointer
  &.cursorUnset
    cursor: unset
.buttonContainer
  text-align: end
  .inactiveButton
    cursor: default
  .inProgressLabel
    color: $TEXT_THIRTARY
    cursor: default
  .completedLabel
    color: $TEXT_SECONDARY
.openGroupPopupTitle
  display: flex
  gap: 10px
  align-items: center
  color: $TEXT_THIRTARY

@media (max-width: $breakpoint-lg)
  .profileContent
    .name,
    .description
      max-width: 100%
