@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.wrapper
  width: 100vw
  height: 100vh
  position: fixed
  left: 0
  top: 0
  display: flex
  justify-content: center
  align-items: center
  background: #00000066
  z-index: 100
  .container
    max-width: 100%
    overflow: auto
    @include scrollbar
    max-height: calc(100vh - 75px)
    background: $WHITE
    display: flex
    flex-shrink: 0
    flex-direction: column
    border-radius: 8px
    z-index: 150
    padding: 24px
    gap: 24px
    box-shadow: 0px 5px 8px 0px $POPUP_SHADOW
    .headerAndTitle
      gap: 24px
      display: grid
    .children
      width: 100%
      gap: 32px
      display: grid
    .footer
      display: flex
      justify-content: flex-end
      gap: 16px
      .dangerSecondaryButton
        border-color: $RED
        color: $RED
      .dangerPrimaryButton
        background: $RED
    .scrollableContent
      overflow-x: hidden
      overflow-y: auto
      @include scrollbar($scrollbarL)
      padding-right: 10px
.header
  width: 100%
  text-overflow: ellipsis
  display: flex
  justify-content: space-between
  align-items: center
  > div
    display: flex
    align-items: center
  .close
    margin-left: auto
    cursor: pointer
    &:hover
      > svg
        stroke: $BLUE
  .title
    @include DesktopUIXLMedium()
    overflow: hidden
    gap: 12px
    color: $TEXT_PRIMARY
    div
      display: flex

@media (max-width: $breakpoint-sm)
  .container
    max-width: calc(100vw - 32px)
@media (max-width: $breakpoint-xs)
  .mobileWrapper
    width: 100%
    height: 100%
    backdrop-filter: unset
    .mobileContainer
      border-radius: unset
      width: 100%
      max-width: 100vw
      height: 100vh
      max-height: 100vh
      position: fixed
      left: 0
      top: 0
      display: flex
      flex-direction: column
