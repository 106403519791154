@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.customLanguageDropdownStyles
  margin-top: 300px
  margin-left: -140px
  position: fixed
  gap: 8px
  .languageDropdownItemStyles
    padding: 4px 26px 4px 8px
    gap: 8px
    >p
      @include DesktopUISRegular()
      min-width: 60px

.languageTitleSection
  width: 100%
  display: flex
  justify-content: space-between
  cursor: initial
  color: $TEXT_PRIMARY
  .title, .button
    display: flex
    gap: 8px
    align-items: center
  .button
    cursor: pointer
    > p
      display: contents
