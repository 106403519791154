@import "common/styles.module.sass"

@keyframes spin
  @include spinner

.networkWrapper
  justify-content: center
  .responsiveLayoutRight
    @include networkLayoutRight()
  .rightContent
    max-width: 650px
    width: 100%
    padding: 0
    box-shadow: none
    background: none
    margin-top: 20px
    height: calc(100vh - #{$FOOTER_HEIGHT} - #{$NAVBAR_HEIGHT} - 6px - 20px)

