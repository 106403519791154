@import "common/styles.module.sass"

.wrapper
  width: 100vw
  height: 100vh
  position: fixed
  left: 0
  top: 0
  display: flex
  justify-content: center
  align-items: center
  background: rgba(0, 0, 0, 0.25)
  backdrop-filter: blur(4px)
  z-index: 100
  .container
    overflow: auto
    @include scrollbar
    max-height: calc(100vh - 100px)
    background: $WHITE
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.01)
    display: flex
    flex-shrink: 0
    flex-direction: column
    border-radius: 10px
    z-index: 150
    padding: 16px 2px 24px 24px
    .children
      width: 100%
      padding-right: 20px
    .footer
      display: flex
      justify-content: flex-end
      gap: 16px
      padding-right: 20px
      .dangerSecondaryButton
        border-color: $RED
        color: $RED
      .dangerPrimaryButton
        background: $RED
    .scrollableContent
      overflow-x: hidden
      overflow-y: auto
      @include scrollbar
      padding-right: 10px
    .header
      width: 100%
      text-overflow: ellipsis
      display: flex
      justify-content: space-between
      align-items: center
      padding-bottom: 16px
      padding-right: 16px
      > div
        display: flex
        align-items: center
      .close
        cursor: pointer
        &:hover
          > svg
            stroke: $BLUE
      .title
        overflow: hidden
        gap: 12px
        @include fontSize20($DARK_BLUE, 500)
        line-height: 1.3
        div
          display: flex

@media (max-width: $breakpoint-sm)
  .container
    max-width: calc(100vw - 32px)
@media (max-width: $breakpoint-xs)
  .mobileWrapper
    width: 100%
    height: 100%
    backdrop-filter: unset
    .mobileContainer
      border-radius: unset
      width: 100%
      max-width: 100vw
      height: 100vh
      max-height: 100vh
      position: fixed
      left: 0
      top: 0
      display: flex
      flex-direction: column
