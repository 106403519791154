@import "common/styles.module.sass"

@keyframes spin
  @include spinner

.container
  @include networkContainer()
  .listContainer
    @include listContainer()
    .secondSubtitle
      text-transform: capitalize
      padding: 36px 0 8px 0 

.emptyStateTitle
  display: flex
  gap: 10px
  justify-content: center
  align-items: center

@media (max-width: $breakpoint-lg)
  .container
    width: 100%
    max-width: 100%
    height: calc(100vh - 3 * #{$PADDING_BOTTOM_PAGE} - #{$NAVBAR_TABLET_HEIGHT} - #{$NAVBAR_MOBILE_HEIGHT} - #{$NAVBAR_MOBILE_BOTTOM_DISTANCE})
    .listContainer
      .margin
        margin: 8px 16px

@media (max-width: $breakpoint-xs)
  .container
    border-radius: unset
    @include heightScrollableComponentParent()
    .listContainer
      padding: 0 24px
    .listContainer
      margin: unset
