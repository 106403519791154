@import "common/styles.module.sass"
@import "common/typography/styles.module.sass"

.searchContainer
  display: flex
  align-items: center
  flex: auto
  height: 48px
  position: relative
  > svg
    z-index: 9
    position: absolute
    left: 14px
  input
    padding: 0 44px
    background: $SHADES_PRIMARY_3
    height: 48px
.search
  @include DesktopUIMRegular()
  display: inline-block 
  border-radius: 28px
  padding: 9px 16px 9px 16px
  border: 1px solid $TRANSPARENT_WHITE
  width: 100%
  height: 42px
  color: $TEXT_PRIMARY
  &::placeholder
    color: $TEXT_THIRTARY
  &:focus
    outline: unset
    background-color: $SHADES_PRIMARY_3
  &:hover
    border: 1px solid $SHADES_PRIMARY_30
  &.default
    background: $BACKGROUND_LIGHT_01

.whiteState
  background: $WHITE !important
  input
    padding: 0 44px
  &:hover
    border: 1px solid $SHADES_PRIMARY_30
  &::placeholder
    color: $TEXT_THIRTARY

.close
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  height: 32px
  width: 32px
  right: 15px
  position: absolute
  border-radius: 17px
  &:hover
    background: $SHADES_PRIMARY_10
